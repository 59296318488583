export const nl = {
  Login: "Inloggen",
  Logout: "Uitloggen",
  Translation: "English",
  Dashboard: "Dashboard",
  "Footer content": "Footer content",
  Link: "Link",
  Welcome: "Welkom",
  "Login with DigiD": "Inloggen met DigiD",
  "Login with your account": "Inloggen met account",
  "Oops, something went wrong": "Oops, er is iets fout gegaan",
  Username: "Gebruikersnaam",
  Password: "Wachtwoord",
  Send: "Verzenden",
  Name: "Naam",
  Active: "Actief",
  "Last run": "Laatste run",
  "Next run": "Volgende run",
  "Date created": "Datum gecreeërd",
  "Date modified": "Datum gewijzigd",
  Description: "Beschrijving",
  "Back to sources": "Terug naar sources",
  Objects: "Objecten",
  Add: "Toevoegen",
  "Add Action": "Action Toevoegen",
  "Add Source": "Source Toevoegen",
  "Add Cronjob": "Cronjob Toevoegen",
  "Add Endpoint": "Endpoint Toevoegen",
  "Add Object": "Object Toevoegen",
  "Add Schema": "Schema Toevoegen",
  "Add Collection": "Collection Toevoegen",
  "Add User group": "User group Toevoegen",
  Attributes: "Attributen",
  ObjectEntities: "Object Entiteiten",
  "Back to objects": "Terug naar objecten",
  "Object detail page": "Object detail pagina",
  "Action handler": "Action handler",
  Handler: "Handler",
  Schema: "Schema",
  Gateway: "Source",
  "The minLength is bigger than the maxLength": "De minLength is groter dan de maxLength",
  "The minItems is bigger than the maxItems": "De minItems is groter dan de maxItems",
  "The minProperties is bigger than the maxProperties": "De minProperties is groter dan de maxProperties",
  "The minFileSize is bigger than the maxFileSize": "De minFileSize is groter dan de maxFileSize",
  "The minDate is bigger than the maxDate": "De minDate is groter dan de maxDate",
  "No properties found": "Geen properties gevonden",
  "No logs found": "Geen logs gevonden",
  "No objects found": "Geen objecten gevonden",
  "No plugins found": "Geen plugins gevonden",
  "No schemas found": "Geen schemas gevonden",
  "No actions found": "Geen actions gevonden",
  "No sources found": "Geen sources gevonden",
  "No cronjobs found": "Geen cronjobs gevonden",
  "No endpoints found": "Geen endpoints gevonden",
  "Search on name": "Zoek op naam",
  "Update to": "Updaten naar",
};
